<template>
  <div class="container">
    <!--<img alt="Vue logo" src="../assets/logo.png">-->
      <div class="row">
        <!--<div class="col-lg-8">
          	<img src="../assets/titre.png" style="max-width:80%;">
        </div>-->
        <!--<div class="col-lg-12">
          	<img src="../assets/papillon.png" style="max-width: 20%;">
        </div>-->
		<div class="col-lg-12">
			<div style="position: relative;">
				<img style="display: block;" :src="require('@/assets/TopBGPicture.jpg')" class="w-100">
				<p class="text-overlay">LE P'TIT DU GROS</p>
				<p class="text-overlay2">11-12-13 SEPT. 2025</p>
			</div>
			<h2 style="font-size:4vw;" class="m-2">Prochain événement</h2>
			<div>
				<a href="https://www.sasdelemont.ch/fr/"><img :src="require('@/assets/SASEvent.jpg')" class="w-100"></a>				
			</div>			
			<h2 style="font-size:4vw;" class="m-2">Plus d'informations</h2>
			<p style="font-size:3vw;margin-left:20%;margin-right: 20%;" class="border border-white rounded-5"><a style="color: #42b983;" href="https://www.sasdelemont.ch/"> Prélocations bientôt disponibles sur sasdelemont.ch</a></p>
		</div>
    </div>
  </div>
  <!--<div class="container">
    
    Partenaire
    <hr><h2>Partenaire</h2>
		<div class="row text-center mt-5">
			<div class="col">
				<img src="../assets/partlogo/1.Chant_Du.png" width="64">
			</div>
			<div class="col">
				<img src="../assets/partlogo/2.PLN.jpeg" width="64">
			</div>
			<div class="col">
				<img src="../assets/partlogo/3.BFM.png" width="64">
			</div>
			<div class="col">
				<img src="../assets/partlogo/4.Fondation_Barriere_Casino.png" width="64">
			</div>
			<div class="col">
				<img src="../assets/partlogo/5.E_Solaire.png" width="64">
			</div>
			<div class="col">
				<img src="../assets/partlogo/6.Goalline.png" width="64">
			</div>
			<div class="col">
				<img src="../assets/partlogo/7.Pain_D_Antan.png" width="64">
			</div>
			<div class="col">
				<img src="../assets/partlogo/8.Camille_Bloch.png" width="64">
			</div>
			<div class="col">
				<img src="../assets/partlogo/9.Haefli.png" width="64">
			</div>
		</div>
  </div>-->
</template>

<script>
// @ is an alias to /src
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  name: 'HomeView',
  components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
  }
}
</script>
<style>
	.text-overlay {
		position: absolute;
		top: 5%;
		left: 30%;
		transform: translate(-50%, -50%);
		color: white;
		font-size: 5vw;
		font-weight: bold;
		text-align: center;
	}
	.text-overlay2 {
		position: absolute;
		top: 20%;
		left: 35%;
		transform: translate(-50%, -50%);
		color: white;
		font-size: 4vw;
		text-align: center;
	}
	.text-overlay3 a{
		position: absolute;
		top: 40%;
		left: 85%;
		transform: translate(-50%, -50%);
		color: #42b983;
		font-size: 3vw;
		font-weight: bold;
		text-align: center;

	}
</style>
